up.compiler('[up-replace]', (element) =>{
    var target = element.getAttribute('up-replace');
    element.addEventListener("click", function(){
        up.replace(target, window.location.pathname);
    });
});

up.compiler('.modal--close', (element) =>{
    if(element.closest('.up-modal-dialog') != undefined){
        element.setAttribute('up-close', '')
        element.setAttribute('up-history', document.referrer)
    }
});

up.compiler('input[up-history]', (element) =>{
    var url = element.getAttribute('up-history')
    element.onclick = function(){up.history.push(url)}
});

up.compiler('a[up-request]', (element) =>{
    if(element.getAttribute('up-request') == 'true') {
        up.follow(element)
    }
});
