import {Dropzone} from "dropzone";
let dropzone_counter = 0;

up.compiler('.dropzone', (element) => {
    if($(element)[0].dropzone != undefined){
        return;
    }

    if (!element.hasAttribute('id')) {
        element.setAttribute('id', 'dropzone'+dropzone_counter)
        dropzone_counter += 1;
    }

    const dropzone = new Dropzone('#'+element.getAttribute('id'), {
        autoProcessQueue: element.hasAttribute('autoProcess'),
        withCredentials: true,
        headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
        addRemoveLinks: true,
        previewsContainer: ".dropzone-previews",
        hiddenInputContainer: '#layout',
        uploadMultiple: element.hasAttribute('multiple'),
        parallelUploads: 5,
        maxFiles: parseInt(element.getAttribute('maxFiles')),
        url: window.location.pathname + '/upload',
        dictCancelUpload: 'Abbrechen',
        dictUploadCanceled: 'Abgebrochen',
        dictCancelUploadConfirmation: 'Upload wirklich abbrechen?',
        dictRemoveFile: 'Entfernen',
        dictMaxFilesExceeded: 'Es können keine weiteren Dateien hinzugefügt werden.',

        init: function() {
            const input = element.querySelector('input[type="file"]');
            const form = input.form;

            this.on("sending", function(file, xhr, formData){
                if(element.hasAttribute('identifier')) {
                    formData.append("identifier", element.getAttribute('identifier'));
                }
            });

            this.on("success", function (file, response) {
                // do not act on success, because its triggered multiple times
            });

            this.on("successmultiple", function(files, response) {
                files.forEach(f => {
                    let matchIdx = response.findIndex(id => id.includes(f.name));
                    let match = response.splice(matchIdx,1)[0];
                    f.cacheId = match;
                });

            });

            this.on("removedfile", function(file) {
                let path = window.location.pathname +'/delete_file';
                let params = { cache_id: file.cacheId };
                up.request(path, { params: params, method: 'DELETE', cache: false})
            });

            form.addEventListener('submit', function(evt){
                let values = [];
                dropzone.files.forEach(f => {
                    values.push(f.cacheId);
                });
                input.nextSibling.setAttribute('value', values);
            });
        }
    });
});

up.compiler('.dropzone-errors', (element) => {
    const dropzone = document.querySelector(`.dropzone[identifier="${element.getAttribute('for')}"]`);
    if(dropzone != null) {
        if (element.children[0].innerHTML != "") {
            const dropzone = document.querySelector(`.dropzone[identifier="${element.getAttribute('for')}"]`);
            let errordiv = dropzone.parentElement.querySelector(".invalid-feedback");
            if (errordiv == null) {
                errordiv = document.createElement('div');
                errordiv.classList.add('invalid-feedback');
                errordiv.classList.add('d-block');
                dropzone.parentElement.appendChild(errordiv);
            }

            errordiv.innerHTML = element.children[0].innerHTML;
            dropzone.style.borderColor = "#dc3545";
            element.remove();
        } else {
            dropzone.style.borderColor = "#ced4da";
            let errordiv = dropzone.parentElement.querySelector(".invalid-feedback");
            if (errordiv) {
                errordiv.remove();
            }
        }
    }
});
