up.compiler('.form-group.radio_buttons', (element) => {
    let buttons = element.querySelectorAll('input');
    buttons.forEach( (button) => {
        button.addEventListener('input', toggleRadios);
        if(button.checked){
            setActive(button.parentNode)
        }
    })
})


function toggleRadios(event){
    let clicked = event.target.parentNode;
    setActive(clicked);
    for (let sibling of clicked.parentNode.children) {
        if (sibling.tagName !== 'INPUT' && sibling !== clicked) setIncative(sibling);
    }

    let input = clicked.querySelector('input[type="radio"]');
    clicked.closest('.input-group').querySelector('input[type="hidden"]').setAttribute('value', input.value);
}

function setActive(formcheck){
    let label = formcheck.querySelector('label');
    label.classList.add('active', 'text-white');
    label.classList.remove('text-primary');
}

function setIncative(formcheck){
    let label = formcheck.querySelector('label');
    label.classList.remove('active', 'text-white');
    label.classList.add('text-primary');
}