require("@rails/ujs").start()
require("@rails/activestorage").start()

import 'core-js/stable' // ES6 polyfills
import 'unpoly'
import 'moment'

import 'bootstrap'
import 'tempusdominus-bootstrap-4'
import 'bootstrap-daterangepicker'
import 'bootstrap4-toggle/js/bootstrap4-toggle.min'
import 'bootstrap-select/dist/js/bootstrap-select.min'
import '@fortawesome/fontawesome-free/js/all'
import 'chartkick/chart.js'
import 'bootstrap-autocomplete/dist/latest/bootstrap-autocomplete'

import { Dropzone } from "dropzone";
Dropzone.autoDiscover = false;

// JavaScript
let webpackContext = require.context('../javascripts', true, /\.js(\.erb)?$/)
for(let key of webpackContext.keys()) { webpackContext(key) }

// Images
require.context('../images', true, /\.(?:png|jpg|gif|ico|svg)$/)

// Stylesheets
import '../stylesheets/custom_bootstrap'
import '../stylesheets/unpoly.sass'
import 'dropzone/dist/dropzone.css'



//require.context('../stylesheets/theme', true, /\.s[ac]ss(\.erb)?$/)
require.context('../stylesheets/blocks', true, /\.s[ac]ss(\.erb)?$/)
require.context('../stylesheets/ext', true, /\.s?[ac]ss(\.erb)?$/)
require.context('../stylesheets/general', true, /\.s?[ac]ss(\.erb)?$/)