up.compiler('.btn-group-toggle.up-toggle', (element) => {
    var group_elements = element.querySelectorAll('a, button');

    var activate_first = true;
    group_elements.forEach((button) => {
        if (button.classList.contains('active')) {
            activate_first = false
        }
        button.addEventListener('click', function(){
            group_elements.forEach((other_button) => {
                deactivate(other_button);
            })
            activate(button);
        })
    })

    if (activate_first) {
        activate(group_elements[0]);
    }
})


function deactivate(element){
    if (element.classList.contains('active')) {
        element.classList.remove('active');
        element.setAttribute('aria-pressed', 'false')
    }
}

function activate(element){
    element.classList.add('active');
    element.setAttribute('aria-pressed', 'true')
}