// delay these unpoly validations to allow other event handlers do their thing before validation change fragments

up.$compiler('.datetime_picker', ($element) => {
    const now = moment();
    $element.datetimepicker({
        locale: 'de'
    });
    $element.on('change.datetimepicker', function(e){
        if(e.oldDate != null) {
            let input = $element.find('input');
            setTimeout(() => {
                up.validate(input, {target: '.timing'});
            }, 1);
        }
    });
})

up.$compiler('.date_picker', ($element) => {
    const now = moment();
    $element.datetimepicker({
        locale: 'de',
        format: 'L'
    });
    $element.on('change.datetimepicker', function(e){
        if(e.oldDate != null) {
            let input = $element.find('input');
            if(input.attr('timeless') != 'true') {
                setTimeout(() => {
                    up.validate(input, {target: '.timing'});
                }, 1);
            }
        }
    });
})

up.$compiler('.time_picker', ($element) => {
    $element.datetimepicker({
        locale: 'de',
        format: 'LT'
    });
    $element.on('change.datetimepicker', function(e){
        if(e.oldDate != null) {
            let input = $element.find('input');
            setTimeout(() => {
                up.validate(input, {target: '.timing'});
            }, 1);
        }
    });
})

up.$compiler('.clockpicker', ($element) => {
    $element.clockpicker({
        placement: 'top',
        autoclose: true,
        twelvehour: false,
        default: 'now',
        donetext: 'OK',
        afterDone: triggerChange
    });

    function triggerChange(){
        let input = $element.find('input')[0];
        setTimeout(() => {  up.validate(input, {target: '.timing'}); }, 1);
     }

})