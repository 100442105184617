up.compiler('.radio-icon-set', (element) => {
    let iconset = JSON.parse(element.attributes['icons'].value);
    let collection = element.parentNode;

    for (label in iconset) {
        var input_list = collection.querySelectorAll('input[value="' + label + '"]');
        input_list.forEach( (input) => {
            let parent = input.parentNode;
            let icon_classes = iconset[label].split(' ');
            icon_element = document.createElement("i");
            icon_element.classList.add(...icon_classes);

            input.labels.forEach( (label) =>{
                let txt_span = document.createElement('span');
                txt_span.classList.add('pl-2');
                txt_span.textContent = label.innerText;
                label.innerHTML = '';
                label.append(icon_element, txt_span);
            });
        });
    }

    element.parentNode.removeChild(element);
})